import * as G from '../../styles/global.styles'
import * as S from './OpenSoonBlock.styled'
import {
  WagtailBlockProps,
  ButtonAPIProps,
  ButtonProps,
  ButtonWithIconProps
} from '../../services/api/types'
import { getPanelIconSet } from '../../services/api/helpers'
import { ButtonWithIcon } from '../base/ButtonWithIcon'
import { ButtonLink } from '../base'

export interface OpenSoonBlockAPIProps extends WagtailBlockProps {
  value: {
    open_text: string
    cta_button: ButtonAPIProps,
    gym_title?: string
  }
  id: string
}

export interface OpenSoonBlockProps {
  openText: string
  gymTitle?: string
  iconButton?: ButtonWithIconProps
  button?: ButtonProps
}

export const OpenSoonBlock = (props: OpenSoonBlockProps) => {
  const { openText, gymTitle, iconButton, button } = props

  return (
    <G.Section spacing={12}>
      <G.Container>
        <S.OpeningBannerWrapper>
          <S.OpeningBannerTitle color='blue'>
            Plus fitness {gymTitle}
          </S.OpeningBannerTitle>
          <S.OpeningBannerTitle color='orange'>
            Opening in {openText}
          </S.OpeningBannerTitle>
          <S.OpeningSoonButtonWrapper>
            {
              button && <ButtonLink {...button}>{button.label}</ButtonLink>
            }
            {
              iconButton && iconButton.iconImgUrl && <ButtonWithIcon {...iconButton} />
            }
          </S.OpeningSoonButtonWrapper>
        </S.OpeningBannerWrapper>
      </G.Container>
    </G.Section>
  )
}

OpenSoonBlock.ApiBlockToProps = (
  data: OpenSoonBlockAPIProps
): OpenSoonBlockProps => {
  const apiData = data.value

  let buttonWithIcon: ButtonWithIconProps = {
    color: 'blue'
  }
  let button: ButtonProps = { color: 'blue' }
  const { cta_button } = apiData
  if (cta_button) {
    button = {
      label: cta_button.button_text,
      color: cta_button.button_colour,
      href:
        cta_button.button_link.document_link ||
        cta_button.button_link.external_link ||
        cta_button.button_link.page_link ||
        cta_button.button_link.anchor_link ||
        undefined,
      target: cta_button.button_link.link_target
    }

    if (cta_button.button_icon?.icon) {
      const iconSet = getPanelIconSet(cta_button.button_icon.icon)
      buttonWithIcon = {
        ...button,
        iconImgUrl: iconSet?.icon
      }
    }
  }

  return {
    openText: apiData.open_text,
    gymTitle: apiData.gym_title,
    iconButton: buttonWithIcon,
    button: button
  }
}

export default OpenSoonBlock
