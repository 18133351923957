import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'
import {fontSize, size} from "../../styles/theme";
import bg from '../../static/imgs/circle-grey.png'

export const ContentWrapper = styled.div`
  ${tw`w-full flex flex-wrap items-center justify-center content-center`}
`
export const ItemWrapper = styled.div`
  ${tw`m-4`}
`

export const AvatarWrapper = styled.div`
  width: 300px;
  max-width:300px;
  background-image: url(${bg});
  ${tw`p-6 bg-contain bg-no-repeat bg-center flex items-center justify-center content-center mb-4`};
`
export const Avatar = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 50%;
  overflow: hidden;
  ${tw`max-w-full max-h-full`}
`

export const Name = styled(G.Text)`
  ${tw`mb-4`}
`
export const Readmore = styled(G.Link)`
  ${tw`text-orange hover:text-blue`}
`

export const PopupWrapper = styled.div`
  ${tw`fixed w-screen h-screen z-40 flex items-center justify-center content-center p-4`}
  z-index: 100;
  padding-top: 0;
  padding-bottom: 0;
  top: 0;

  @media (max-width: 414px) {
    align-items: flex-start;
    overflow-y: auto;
  }
`

export const PopupContainer = styled.div`
  ${tw`w-full bg-white w-full lg:w-3/4 xl:w-2/3 py-12 px-4 md:px-12 relative shadow-2xl`}
`
export const Close = styled.a`
  ${tw`absolute top-0 right-0 pr-4 md:pr-8 text-4xl font-bold text-grey__medium hover:text-blue cursor-pointer`}
`
export const PopupContent = styled.div`
  ${tw`w-full flex flex-col md:flex-row`}
  overflow-y:auto;
`
export const AvatarWrapperPopup = styled.div`
  ${tw`flex flex-col`}
  @media (max-width: 414px) {
    max-width:15rem;
  }
`
export const PopupAvatar = styled.img`
  ${tw`max-w-sm flex-initial`}
`
export const PopupInfo = styled.div`
  ${tw`flex-1 pt-4 px-0 md:pr-8 md:pl-12 md:pt-0`}
`
export const PhoneWrapper = styled.div`
  ${tw`py-4`}
`

export const PhoneIconWrapper = styled.span`
  ${tw`mr-2`}
`

export const TitleWrapper = styled.div`
  ${tw`py-4 w-full text-center`}

`

export const TextDescription = styled.div`
  ${tw`text-grey__medium`}
  overflow-y:auto;
  height: ${size(313)}

  @media (max-width: 768px){
    height: ${size(218)}
  }
`

export const TextDescriptionNew = styled.div`
  ${tw`text-white`}
`

export const TrainerWrap = styled.div`
  ${tw`w-full flex justify-center`}
  
  @media (max-width: 768px) {
    ${tw`flex-col`}  
  }
`

export const TrainerBlock = styled.div`
  width: 50%; 
  max-width: ${size(218)};
  margin: 0 ${size(30)}; 
  
   @media (max-width: 768px) {
    width: 90%;   
    max-width: none;
    margin: 0 auto;
    margin-bottom: ${size(50)};
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const TrainerImage = styled.div`
  height: ${size(218)} 
  
  @media (max-width: 768px) {
    height: ${size(618)}
  }
  
  @media (max-width: 480px) {
    height: ${size(275)}
  }
`

export const TrainerName = styled.p`
  margin-bottom: 0; 
  ${tw`bg-white text-center`}
  ${fontSize(16)}
  padding: ${size(5.5)} 0;
`

export const ReadMoreNewLayout = styled.p`
  cursor: pointer;
  ${tw`text-white text-center`}
  margin-top: ${size(12)};
`

export const PopupContainerNewLayout = styled.div`
  ${tw`w-full bg-black__deep relative shadow-2xl`}
  max-width: ${size(808)};
  padding: ${size(20)};
  padding-bottom: ${size(70)};
  
  @media (max-width: 480px) {
    padding: ${size(20)} ${size(32)};
    padding-bottom: ${size(40)};
  }
`

export const PopupHeader = styled.div`
  ${tw`flex justify-end`}
  
  span {
    svg {
      width: ${size(20)};
      height: ${size(20)};
      cursor: pointer;
      
       @media (max-width: 414px) {
        ${tw`relative mr-0`}
        left: 1.2rem;
      }
    }
  }
`

export const PopupFooter = styled.div`
  ${tw`flex justify-center`}
  a {
    margin: 0 ${size(8)};
    
    &.call-now {
      display: none;
      
      @media (max-width: 480px) {
        display: flex;
        white-space: nowrap;
      }
    }
  }
  
  @media (max-width: 360px) {
    ${tw`flex-col`}
    a {
      margin-bottom: ${size(10)};
      ${tw`justify-center`}  
    }
  }
`

export const PopupBody = styled.div`
  
`

export const GymComp3Title = styled.p`
  ${tw`uppercase text-center text-white font-bold relative`}
  ${fontSize(30)}
  padding: 0 ${size(16)};
  padding-bottom: ${size(25)};
  &::before {
    content: '';
    width: 100%;
    max-width: ${size(70)};
    height: ${size(4)};
    ${tw`bg-blue__deep absolute`}
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
  }
  
  @media (max-width: 480px) {
    ${tw`text-left`}
    padding-bottom: ${size(20)};
    
     &::before {
      content: '';
      width: 100%;
      max-width: ${size(70)};
      height: ${size(4)};
      ${tw`bg-blue__deep absolute`}
      left: ${size(52)};
      bottom: 0;
      transform: translate(-50%, -50%);
    }
  }
`

export const PopupCompContact = styled.div`

  ${tw`flex justify-center text-white`}
  a {
    ${tw`flex`}
    margin: 0 ${size(16)};
    ${fontSize(14)}
    
    div {
      text-decoration: underline;
    }
        
    span {
      text-decoration: none;
      margin-right: ${size(5)}
    }
  }

  
  @media (max-width: 480px) {
    ${tw`flex-col`}
  }
`

export const PopupLink = styled.a`
  
`

export const PopupMainBody = styled.div`
  ${tw`flex justify-center text-white`}
  padding-top: ${size(32)};
  padding-bottom: ${size(47)};
  
  @media (max-width: 480px) {
    ${tw`flex-col`}
    padding-top: ${size(33)};
    padding-bottom: ${size(27)};
  }
`

export const PopupAvatarNew = styled.div`
  width: 65%;
  max-width: ${size(313)};
  height: ${size(313)};
  padding: 0 ${size(22.5)};
  
  @media (max-width: 480px) {
    ${tw`w-full`}
    height: ${size(275)};
    padding: 0;
    margin-bottom: ${size(45)};
  }
`

export const PopupDescNew = styled.div`
  width: 35%;
  padding: 0 ${size(22.5)};
  
  h1, h2, h3, h4  {
    color: rgb(37, 51, 213);
  }
  @media (max-width: 480px) {
    ${tw`w-full`}
    max-width: none;
    padding: 0;
  }
`

export const btnIcon = styled.figure`
  
`
