import { ImageLink } from '../../../services/api/types'
import { Container } from '../../../styles/grid.styles'
import { MainSectionAPIProps } from '../../main-section/MainSection'
import { useEffect, useRef, useState } from 'react'
import { LazyImage } from '../../lazy-image'
import { ButtonLink } from '../../base'
import * as S from './GymDescription.styled'

export interface GymDescriptionProps {
  images: ImageLink[]
}

interface BannerProps {
  index: number
  image: ImageLink
}

const Banner = ({ index, image }: BannerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [triangleHeight, setTriangleHeight] = useState(300)

  useEffect(() => {
    ref.current?.clientHeight &&
      setTriangleHeight(ref.current?.clientHeight + 5)
  }, [ref])
  return (
    <S.Section>
      <Container fullWidthMobile>
        <S.Banner custom={index % 2 ? true : false} bgImage={image.imageUrl}>
          <S.BannerImage bgImage={image.imageUrl}>
            <LazyImage src={image.imageUrl} alt={image.imageAlt} width='100%' />
          </S.BannerImage>
          <S.BannerInfo
            custom={index % 2 ? true : false}
            ref={ref}
            triangleHeight={triangleHeight}
          >
            <S.BannerTitle>{image.title}</S.BannerTitle>
            <S.BannerContent custom={index % 2 ? true : false}>
              {image.title_large_text}
            </S.BannerContent>
            {image.url && (
              <S.ButtonWrapper>
                <ButtonLink
                  href={image.url}
                  color='blue-deep'
                  target={image.imageUrlTarget}
                >
                  {image.banner_link_text}
                </ButtonLink>
              </S.ButtonWrapper>
            )}
          </S.BannerInfo>
        </S.Banner>
      </Container>
    </S.Section>
  )
}

const GymDescription = ({ images }: GymDescriptionProps) => {
  return (
    <>
      {images && images.length > 0
        ? images.map((img, index) => (
            <Banner index={index} key={index} image={img} />
          ))
        : null}
    </>
  )
}

GymDescription.ApiBlockToProps = (data: MainSectionAPIProps) => {
  const { value } = data

  if (value) {
    let props = {
      images:
        value.promo_banners &&
        value.promo_banners.length > 0 &&
        value.promo_banners.map((item: any) => {
          return {
            imageUrl: item.image,
            url:
              item.banner_link.page_link ||
              item.banner_link.external_link ||
              item.banner_link.document_link ||
              '',
            imageAlt: item.alt,
            imageUrlTarget: item.banner_link.link_target,
            title: item.title,
            title_large_text: item.title_large_text,
            banner_link_text: item.banner_link_text
          }
        })
    } as GymDescriptionProps
    return props
  }
}

export default GymDescription
