import * as S from './Hero.styles'
import * as G from '../../styles/global.styles'
import Head from 'next/head'
import {
  TextProps,
  ButtonProps,
  WagtailBlockProps,
  ButtonAPIProps,
  FirstContentBlock
} from '../../services/api/types'
import moment from 'moment'
import { DefaultAlt } from '../../services/api/constants'
import Modal from '../modal/Modal'
import { GymSearchCard } from '../gym-search-card/GymSearchCard'

export interface HeroAPIProps extends WagtailBlockProps {
  value: {
    slides: SlideAPIProps[]
    enable?: boolean
    is_gym?: boolean
    name?: string
    address?: string
    sub_address?: string
    is_kick_gear?: boolean
  }
}

export interface ImageLinkProps {
  anchor_link?: string
  document_link?: string
  external_link?: string
  link_target?: string
  page_link?: string
}

export interface SlideAPIProps {
  type: 'HeroSlide' | 'MembershipSlide' | 'ImageSlide' | 'BlogHeroSlide'
  value: {
    background_image?: {
      image: string
      mobile_image: string
    }
    headline?: string
    subtext?: string
    cta_buttons?: {
      cta_button: ButtonAPIProps[]
    }
    content_alignment: {
      content_alignment: 'center' | 'left' | 'right'
    }
    blog_post?: any
    id: string
  }
  background_image?: {
    image: string
    mobile_image: string
  }
  link?: ImageLinkProps
}

export interface HeroTextProps extends TextProps {
  spaceTop?: number
  spaceBottom?: number
}

export interface HeroSlideProps extends FirstContentBlock {
  bgImgURL?: string
  bgImgMobileURL?: string
  bgColor?:
    | 'black'
    | 'white'
    | 'blue'
    | 'blue-dark'
    | 'blue-grey'
    | 'blue-dodger'
    | 'orange'
    | 'orange-dark'
    | 'green'
    | 'grey'
    | 'grey-light'
    | 'grey-medium'
    | 'grey-dark'
    | 'grey-sand'
    | 'silver'
  contentPosition?: 'center' | 'left' | 'right'
  contentWidth?: 'full' | '1/2' | '2/3' | '3/4' | '4/5'
  texts?: HeroTextProps[]
  buttons?: ButtonProps[]
  overlay?: boolean
  isHeaderGap?: boolean
  links?: ImageLinkProps
  isGym?: boolean
  isCareer?: boolean
  isShowDetail?: boolean
}
export interface HeroProps extends FirstContentBlock {
  slides: HeroSlideProps[]
  isCareer?: boolean
  enable?: boolean
}

export const HeroSliderItem = (props: HeroSlideProps) => {
  const {
    bgImgURL,
    bgImgMobileURL,
    bgColor,
    contentPosition,
    overlay,
    texts,
    buttons,
    contentWidth,
    isHeaderGap,
    isFirstBlock,
    isGym,
    links,
    isCareer,
    isShowDetail
  } = props

  return (
    <>
      <Head>
        {bgImgURL && <link rel='preload' href={bgImgURL} as='image' />}
        {bgImgMobileURL && (
          <link rel='preload' href={bgImgMobileURL} as='image' />
        )}
      </Head>
      <S.HeroWrapper isHeaderGap={isHeaderGap} isGym={isGym}>
        <S.HeroSection isCareer={isCareer} bgColor={bgColor}>
          <S.ImgWrapper>
            {bgImgURL && (
              <S.ImgItem src={bgImgURL} alt={DefaultAlt} isMobile={false} />
            )}
            {(bgImgMobileURL || (bgImgURL && !bgImgMobileURL)) && (
              <S.ImgItem
                src={bgImgMobileURL ? bgImgMobileURL : bgImgURL}
                alt={DefaultAlt}
                isMobile={true}
              />
            )}
          </S.ImgWrapper>
          <G.OverlayWrapper
            overlay={
              isFirstBlock && overlay && ((isGym && isShowDetail) || !isGym)
            }
            className='absolute z-10'
          >
            <S.Container contentPosition={contentPosition}>
              <S.ContentWrapper width={contentWidth}>
                {texts &&
                  texts.map((textProp, idx) => {
                    const textData = {
                      key: idx,
                      ...textProp,
                      dangerouslySetInnerHTML: {
                        __html: textProp.text
                      }
                    }
                    if (
                      (idx == 0 && isFirstBlock) ||
                      (idx == 1 && textProp.textCase == 'upper')
                    ) {
                      return isGym ? (
                        isShowDetail && (
                          <S.HeroText
                            {...textData}
                            className='mb-2'
                          ></S.HeroText>
                        )
                      ) : (
                        <S.HeroTextH2 {...textData}></S.HeroTextH2>
                      )
                    } else {
                      return isGym ? (
                        isShowDetail && (
                          <S.HeroTextAddress>
                            <S.HeroTextWrapper>
                              <S.HeroText
                                {...textData}
                                color='white'
                              ></S.HeroText>
                            </S.HeroTextWrapper>
                          </S.HeroTextAddress>
                        )
                      ) : (
                        <S.HeroTextWrapper>
                          <S.HeroText {...textData} color='white'></S.HeroText>
                        </S.HeroTextWrapper>
                      )
                    }
                  })}
                {buttons && (
                  <S.ButtonGroup contentPosition={contentPosition}>
                    {buttons &&
                      buttons.map((btn, idx) => {
                        if (btn.label) {
                          if (
                            btn.page_type &&
                            (btn.page_type === 'memberships' ||
                              btn.page_type === 'gym-membership')
                          ) {
                            if (btn.href) {
                              return (
                                <S.HeroButtonLink key={idx} {...btn}>
                                  {btn.label}
                                </S.HeroButtonLink>
                              )
                            } else {
                              return (
                                <Modal
                                  buttonText={btn.label}
                                  cancelText='Cancel'
                                  isHeroButton={true}
                                >
                                  <GymSearchCard />
                                </Modal>
                              )
                            }
                          } else {
                            return (
                              <S.HeroButtonLink key={idx} {...btn}>
                                {btn.label}
                              </S.HeroButtonLink>
                            )
                          }
                        }
                      })}
                  </S.ButtonGroup>
                )}
              </S.ContentWrapper>
            </S.Container>
            {links && links.external_link && (
              <S.AnchorWrapper
                href={links.external_link}
                target={links.link_target}
                className='cursor-pointer'
              />
            )}
          </G.OverlayWrapper>
        </S.HeroSection>
      </S.HeroWrapper>
    </>
  )
}

export const Hero = (props: HeroProps) => {
  const { slides, isFirstBlock, isCareer } = props

  const sliderSetting = {
    swipeable: true,
    showArrows: false,
    infiniteLoop: true,
    interval: 3000,
    transitionTime: 700,
    autoPlay: true,
    emulateTouch: true,
    showIndicators: true,
    showStatus: false,
    showThumbs: false
  }

  return (
    <G.FullWidthWrapper>
      {slides.length > 1 ? (
        <S.Carousel {...sliderSetting}>
          {slides &&
            slides.map((slide, index) => (
              <HeroSliderItem
                key={index}
                {...slide}
                isFirstBlock={isFirstBlock && index == 0}
              />
            ))}
        </S.Carousel>
      ) : (
        slides[0] && (
          <HeroSliderItem
            {...slides[0]}
            isCareer={isCareer}
            isFirstBlock={isFirstBlock}
          />
        )
      )}
    </G.FullWidthWrapper>
  )
}

Hero.ApiBlockToProps = (data: HeroAPIProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    const apiData = data.value
    const props: HeroProps = {
      isFirstBlock: data.isFirstBlock,
      slides: apiData.slides.map((slide, idx) => {
        let item: HeroSlideProps = {
          bgColor: 'blue',
          isShowDetail: idx === 0 ? apiData.enable : false,
          contentPosition: apiData.is_gym
            ? 'left'
            : (slide.value &&
                slide.value.content_alignment &&
                slide.value.content_alignment.content_alignment) ||
              'left',
          bgImgURL: apiData.is_gym
            ? slide.background_image?.image
            : slide.value.background_image?.image,
          bgImgMobileURL: apiData.is_gym
            ? slide.background_image?.mobile_image
            : slide.value.background_image?.mobile_image,
          overlay: apiData.is_kick_gear ? false : true,
          isHeaderGap: apiData.is_kick_gear ? false : true,
          buttons: apiData.is_gym
            ? []
            : slide.value.cta_buttons &&
              slide.value.cta_buttons.cta_button?.map(btn => {
                return {
                  color: btn.button_colour,
                  label: btn.button_text,
                  href:
                    btn.button_link.page_link ||
                    btn.button_link.document_link ||
                    btn.button_link.external_link ||
                    btn.button_link.anchor_link ||
                    undefined,
                  target: btn.button_link.link_target,
                  onClick: btn.onClick,
                  page_type: btn.page_type
                }
              }),
          links: apiData.is_gym ? { ...slide.link } : {},
          isGym: apiData.is_gym,
          texts: []
        }

        if (slide.type) {
          switch (slide.type) {
            case 'HeroSlide': {
              if (slide.value.headline) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.headline,
                  fontWeight: 'bold',
                  size: '6xl',
                  textCase: 'upper'
                })
              }

              if (slide.value.subtext) {
                item.texts?.push({
                  color: 'blue',
                  text: slide.value.subtext,
                  fontWeight: 'bold',
                  size: '2xl',
                  spaceTop: 2
                })
              }
              return item
            }
            case 'MembershipSlide': {
              if (slide.value.headline) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.headline,
                  fontWeight: 'bold',
                  size: '6xl',
                  textCase: 'upper'
                })
              }
              return item
            }
            case 'BlogHeroSlide': {
              if (slide.value.blog_post.date) {
                item.texts?.push({
                  color: 'white',
                  text: `${moment(slide.value.blog_post.date).format(
                    'MMMM Do, YYYY'
                  )}`,
                  fontWeight: 'semibold',
                  size: '2xl',
                  textCase: 'upper',
                  spaceBottom: 0.5
                })
              }
              if (slide.value.blog_post.title) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.blog_post.title,
                  fontWeight: 'bold',
                  size: '4xl',
                  textCase: 'upper'
                })
              }
              if (slide.value.blog_post.preamble) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.blog_post.preamble,
                  spaceTop: 2,
                  spaceBottom: 3
                })
              }
              return item
            }
            default: {
              if (slide.value.headline) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.headline,
                  fontWeight: 'bold',
                  size: '6xl',
                  textCase: 'upper'
                })
              }
              if (slide.value.subtext) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.subtext,
                  fontWeight: 'bold',
                  size: '4xl'
                })
              }
              return item
            }
          }
        } else {
          if (idx === 0 && apiData.is_gym) {
            if (apiData.name !== '') {
              item.texts?.push({
                color: 'white',
                text: apiData.name,
                fontWeight: 'bold',
                size: '6xl',
                textCase: 'upper'
              })
            }
            if (apiData.address !== '') {
              item.texts?.push({
                color: 'blue',
                text: apiData.address,
                fontWeight: 'bold',
                size: '2xl'
              })
            }
            if (apiData.sub_address !== '') {
              item.texts?.push({
                color: 'blue',
                text: apiData.sub_address,
                fontWeight: 'bold',
                size: '2xl'
              })
            }
            return item
          }
          return item
        }
      })
    }
    return props
  }
}

export default Hero
