import { CustomerReviews } from '../customer-reviews'
import { MembershipBenefits } from '../membership-benefits/MembershipBenefits'
import { GymFeatures } from '../gym-features/GymFeatures'
import { WhyOwn } from '../why-own/WhyOwn'
import { Hero, HeroWithForm } from '../hero'
import { StandardContent } from '../standard-content/StandardContent'
import { Banner } from '../banner/Banner'
import { AngledBanner } from '../banner/AngledBanner'
import { Divider } from '../divider'
import {
  ContentBlock,
  VideoBlock,
  CountdownBlock,
  ReadyStartedBlock,
  GymOwnerBlock,
  OpenSoonBlock
} from '../blocks'
import { BlocksTable } from '../blocks-table'
import { SubHeader, HeaderWithTitle } from '../header'
import { WhatsHot } from '../whats-hot'
import { ClubLocation } from '../club-location'
import { Advertisement } from '../advertisement/Advertisement'
import { MainSection } from '../main-section'
import { Menu } from '../menu'
import { GymSuburbs } from '../gym-suburbs'
import { SocialLinks } from '../social-links'
import { ImageGallery } from '../image-gallery'
import { PersonalTrainers } from '../personal-trainers'
import { KeyMessage } from '../key-message'
import { Forms } from '../forms'
import { TrainingOptions } from '../training-options'
import { FAQs } from '../faq'
import { ImageLink } from '../image-link/ImageLink'
import { ResponsiveImage } from '../responsive-image/ResponsiveImage'
import { PerformanceLocationCard } from '../cards'
import { IconSection } from '../icon-section'
import HeroVideo from '../franchise-page/HeroVideo'
import Brochure from '../franchise-page/Brochure'
import OurValues from '../franchise-page/OurValues'
import GymOwners from '../franchise-page/GymOwners'
import GetStarted from '../franchise-page/GetStarted'
import LocationSuggestions from '../franchise-page/LocationSuggestions'
import Media from '../franchise-page/Media'
import { YourGoalsForm } from '../forms/YourGoalsForm'
import { IconLinks } from '../icon-links'
import ImageAndText from "../image-and-text";

const Block = (name: string) => {
  switch (name) {
    case 'FeaturedReviews':
      return CustomerReviews
    case 'FeaturedMembershipBenefits':
      return MembershipBenefits
    case 'gym_features':
      return GymFeatures
    case 'ValueProposition':
      return WhyOwn
    case 'HeroWithForm':
      return HeroWithForm
    case 'paragraph':
      return StandardContent
    case 'Banner':
      return Banner
    case 'angled_image_banner':
      return AngledBanner
    case 'Hero':
    case 'hero':
    case 'gym_hero':
    case 'BlogHeroSlide':
      return Hero
    case 'content':
      return HeaderWithTitle
    case 'divider':
      return Divider
    case 'ContentWithCTA':
      return ContentBlock
    case 'KeyMessage':
      return KeyMessage
    case 'Heading':
      return SubHeader
    case 'CountdownBlock':
      return CountdownBlock
    case 'VideoEmbeds':
      return VideoBlock
    case 'WhatsHot':
      return WhatsHot
    case 'gym_location':
      return ClubLocation
    case 'CTAPanels':
      return ReadyStartedBlock
    case 'Advertisement':
      return Advertisement
    case 'gym_description':
    case 'title_and_richtext':
      return MainSection
    case 'GymPageMenu':
    case 'page_submenu':
    case 'BlogCategoryMenu':
      return Menu
    case 'gym_social_links':
      return SocialLinks
    case 'gym_image_gallery':
      return ImageGallery
    case 'Table':
      return BlocksTable
    case 'GymOwners':
      return GymOwnerBlock
    case 'gym_personal_trainers':
      return PersonalTrainers
    case 'form':
      return Forms
    case 'performance_classes':
      return TrainingOptions
    case 'faq_section':
      return FAQs
    case 'ResponsiveImage':
      return ResponsiveImage
    case 'title_and_image':
      return ImageLink
    case 'plus_performance_locations':
      return PerformanceLocationCard
    case 'gym_servicing_suburbs':
      return GymSuburbs
    case 'YourGoalsForm':
      return YourGoalsForm
    case 'icon_section':
      return IconSection
    case 'icon_link':
      return IconLinks
    case 'gym_opening_soon':
      return OpenSoonBlock
    case 'hero_video':
      return HeroVideo
    case 'brochure':
      return Brochure
    case 'media_block':
      return Media
    case 'our_values':
      return OurValues
    case 'gym_owners':
      return GymOwners
    case 'get_started':
      return GetStarted
    case 'location_suggestions':
      return LocationSuggestions
    case 'image_and_text':
      return ImageAndText
  }
}

export default Block
