import styled from 'styled-components'
import tw from 'twin.macro'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import { HeroTextProps } from './Hero'
import * as G from '../../styles/global.styles'
import { colors, size } from '../../styles/theme'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const HeroSection = styled(G.Section)<{
  bgDesktop?: string
  bgMobile?: string
  isCareer?: boolean
}>`
  background-position: top;
  ${tw`h-full flex justify-center items-center overflow-hidden relative`}

  @media (max-width:380px) {
    min-height: 70vh;

    ${({ isCareer }) => isCareer && `min-height: unset;`}
  }

  @media (min-width: 1024px) {
    ${({ bgDesktop }) =>
      bgDesktop && `background:url('${bgDesktop}') center/cover no-repeat;`}
  }

  @media (max-width: 1023px) {
    ${({ bgMobile }) =>
      bgMobile && `background:url('${bgMobile}') center/cover no-repeat;`}
  }
`
export const HeroWrapper = styled.div<{
  isHeaderGap?: boolean
  isGym?: boolean
  template?: LAYOUT_TEMPLATE
}>`
  ${tw`w-full h-full flex flex-col items-stretch`}
  ${({ isHeaderGap, isGym, template }) => {
    if (isHeaderGap && !(template == PAGE_TEMPLATE.YOU_FITNESS)) {
      if (isGym) {
        return ``
      } else {
        return `
          margin-top: 125px;

          .theme_you_fitness & {
            margin-top: 0 !important;
          }

          @media (min-width: 320px) {
            margin-top: 130px;
          }

          @media (min-width: 640px) {
            margin-top: 110px;
          }
      
          /* Large (lg) */
          @media (min-width: 1024px) {
            margin-top: 40px;
          }
      
          /* Extra Large (xl) */
          @media (min-width: 1280px) {
            margin-top: 40px;
          }
        `
      }
    }
  }}
`

export const Container = styled.div<{
  contentPosition?: 'center' | 'left' | 'right'
}>`
  ${tw`container mx-auto flex flex-col h-full`}
  @media (min-width: 320px) {
    margin-top: 20px;
  }
  ${({ contentPosition }) => {
    switch (contentPosition) {
      case 'center':
        return tw`justify-center text-center`
      case 'left':
        return tw`justify-start text-left`
      case 'right':
        return tw`justify-end text-right`
      default:
        return tw`justify-center text-center`
    }
  }}
`

export const ButtonGroup = styled.div<{
  contentPosition?: 'center' | 'left' | 'right'
}>`
  ${tw`py-2 mt-4 flex flex-wrap flex-row items-center`}
  ${({ contentPosition }) => {
    switch (contentPosition) {
      case 'center':
        return tw`justify-center text-center`
      case 'left':
        return tw`justify-start text-left`
      case 'right':
        return tw`justify-end text-right`
      default:
        return tw`justify-center text-center`
    }
  }}
`

export const HeroText = styled(G.Text as any)<HeroTextProps>`
  text-shadow: 2px 0px 6px ${colors.grey__dark};
  ${tw`lg:leading-none`}
  ${({ size }) => {
    switch (size) {
      case '6xl':
        return tw`leading-8`
      case '4xl':
        return tw`leading-8`
      case '2xl':
        return tw`leading-6`
    }
  }}
  ${({ spaceTop }) => spaceTop && `margin-top:${spaceTop}em;`}
  ${({ spaceBottom }) => spaceBottom && `margin-bottom:${spaceBottom}em;`}
`

export const HeroTextNewLayout = styled(G.Text as any)<HeroTextProps>`
  font-size: 1.875rem !important;

  @media (max-width: 1100px) {
    font-size: ${size(36)} !important;
  }
`

export const HeroTextH1 = styled(G.TextH1 as any)<HeroTextProps>`
  ${tw`lg:leading-none`}
  text-shadow: 2px 0px 6px ${colors.grey__dark};
  ${({ size }) => {
    switch (size) {
      case '6xl':
        return tw`leading-8`
      case '4xl':
        return tw`leading-8`
      case '2xl':
        return tw`leading-6`
    }
  }}
  ${({ spaceTop }) => spaceTop && `margin-top:${spaceTop}em;`}
  ${({ spaceBottom }) => spaceBottom && `margin-bottom:${spaceBottom}em;`}
`

export const HeroTextH2 = styled(G.TextH2 as any)<HeroTextProps>`
  ${tw`lg:leading-none my-0`}
  text-shadow: 2px 0px 6px ${colors.grey__dark};
  ${({ size }) => {
    switch (size) {
      case '6xl':
        return tw`leading-8`
      case '4xl':
        return tw`leading-8`
      case '2xl':
        return tw`leading-6`
    }
  }}
  ${({ spaceTop }) => spaceTop && `margin-top:${spaceTop}em;`}
  ${({ spaceBottom }) => spaceBottom && `margin-bottom:${spaceBottom}em;`}
`

export const HeroButtonLink = styled(G.ButtonLink as any)`
  ${tw`mr-4 py-2 my-2 md:py-4 px-4 lg:px-8 lg:py-2`}
`

export const ContentWrapper = styled.div<{
  width?: 'full' | '1/2' | '2/3' | '3/4' | '4/5'
}>`
  ${tw`w-full px-2 lg:mt-2 flex flex-col justify-center`}
  height: 100%;
  ${({ width }) => {
    switch (width) {
      case '1/2':
        return tw`md:w-2/3 lg:w-1/2`
      case '2/3':
        return tw`lg:w-2/3`
      case '3/4':
        return tw`lg:w-3/4`
      case '4/5':
        return tw`lg:w-4/5`
      case 'full':
        return tw`lg:w-full`
      default:
        return null
    }
  }}
`

export const Carousel = styled(ResponsiveCarousel)`
  .carousel {
    .slider-wrapper {
      &.axis-horizontal {
        .slider {
          ${tw`flex flex-row items-stretch`}
          li.slide {
            ${tw`items-stretch flex flex-col`}
          }
        }
      }
    }
  }
`

export const HeroFormBlock = styled.div<{
  isHideMobile?: boolean
}>`
  margin-top: 0;
  padding-right: 0;
  @media (min-width: 1024px) {
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
  }
  @media (min-width: 1280px) {
    padding-right: 8rem;
  }
  ${({ isHideMobile }) => {
    return isHideMobile ? tw`hidden` : null
  }}
`

export const HeroEnquireFormWrapper = styled.div`
  margin-top: 0;
  @media (min-width: 1024px) {
    margin-top: -40rem;
  }
  @media (min-width: 1280px) {
    margin-top: -75vh;
  }
`

export const HeroTextAddress = styled.address`
  ${tw`mb-0 not-italic`}
`

export const HeroTextWrapper = styled.p`
  ${tw`mb-0`}
`

export const ImgWrapper = styled.div`
  //height: 21rem;
  padding-top: 80px;
  @media (min-width: 1024px) {
    height: 40rem;
  }
  @media (min-width: 1280px) {
    height: 75vh;
  }
  ${tw`z-auto w-full min-h-full min-w-full flex-shrink-0`}
`

export const ImgItem = styled.img<{
  isMobile?: boolean
}>`
  ${({ isMobile }) => {
    return isMobile
      ? `display: block !important;
    @media (min-width: 1024px) {
      display: none !important;
    }`
      : `display: none !important;
    @media (min-width: 1024px) {
      display: block !important;
    }`
  }}
  object-fit: fill;
  //transform: scaleY(1.25);
  
  object-position: center;
  ${tw`w-full h-full`}
`
export const AnchorWrapper = styled.a`
  ${tw`absolute z-10 top-0 left-0 w-full h-full no-underline`}
`
export const Banner = styled.div`
  position: relative;
`

export const BannerImage = styled.img<{
  isMobile?: boolean
}>`
  ${({ isMobile }) => {
    return isMobile
      ? `display: block !important;
    @media (min-width: 1024px) {
      display: none !important;
    }`
      : `display: none !important;
    @media (min-width: 1024px) {
      display: block !important;
    }`
  }}
  object-fit: cover;
  filter: contrast(150%) saturate(50%);
  ${tw`w-full h-full`}
`

export const GroupTextBanner = styled.div`
  ${tw`flex justify-center items-center flex-col`}
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);

  p,
  a {
    ${tw`text-white`}
  }

  p {
    ${tw`font-bold uppercase text-3xl mb-2`}
  }

  a {
    ${tw`border border-white`}
    padding: 4px 17px;

    &:hover {
      ${tw`text-black bg-gray border-gray`}
    }
  }
`
