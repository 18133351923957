import { NextSeo, NextSeoProps } from 'next-seo'
import Layouts, { GenericPageTemplate } from '../layouts'
import { cms, redirects } from '../src/services/api'
import {
  facebookPixelScriptWithDynamicType,
  dynamicGtagWithEvent,
  tiktokPixelScript,
  LinkedInScript,
  stripQueryParam
} from '../src/services/utils'
import { WagtailMetaToNextSEO } from '../src/components/wagtail/index'
import {
  WagtailPageProps,
  PageContext,
  LAYOUT_TEMPLATE
} from '../src/services/api/types'
import { useContext } from 'react'
import { MenuContext } from '../src/services/context/MenuContext'
import { SiteContext } from '../src/services/context/SiteContext'
import { StructureData } from '../src/services/api/SEOHelper'
import {
  BlocksShowPageTitle,
  PAGE_TEMPLATE,
  YOU_FITNESS_TEMPLATE
} from '../src/services/api/constants'
import Error from './_error'
import { useEffect } from 'react'
import FooterTemplate from '../src/components/footer/FooterTemplate'
import NavigationTemplate from '../src/components/navigation/NavigationTemplate'
import { setCookie, getCookieFromBrowser } from '../src/services/api/cookies'
import Header from '../src/components/franchise-page/Header'
import parse from 'html-react-parser'
export interface WagtailProps {
  pageData: WagtailPageProps
  wagtailPageType: string
  pageProps: any
  SEO: NextSeoProps
}

const WagtailPage = (props: WagtailProps) => {
  const { siteInfor, siteSettings } = useContext(SiteContext)

  const { pageData, wagtailPageType, pageProps, SEO } = props
  if (pageProps.statusCode === 404 || pageProps.statusCode === 500) {
    return (
      <Error
        site_template={siteSettings?.site_template}
        statusCode={pageProps.statusCode}
      />
    )
  }

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const utmSourceValue = currentUrl.searchParams.get('utm_source')
    const utmCampaignValue = currentUrl.searchParams.get('utm_campaign')
    const utmSourceCookie = getCookieFromBrowser('utm_source')
    const utmCampaignCookie = getCookieFromBrowser('utm_campaign')

    if (utmSourceValue || utmCampaignValue) {
      const expireDay = 7
      !utmSourceCookie &&
        utmSourceValue &&
        setCookie('utm_source', utmSourceValue, expireDay)
      !utmCampaignCookie &&
        utmCampaignValue &&
        setCookie('utm_campaign', utmCampaignValue, expireDay)
    }
  }, [])

  let Page = Layouts(wagtailPageType)

  if (!Page) {
    Page = GenericPageTemplate
  }

  const { flexible_content, meta } = pageData
  const firstBlockType = (flexible_content && flexible_content[0]?.type) || ''
  const trackingFacebookPixel =
    flexible_content &&
    !!flexible_content.length &&
    flexible_content.filter(x => x.type == 'facebook_tracking')

  const gtag_with_event = flexible_content &&
    !!flexible_content.length &&
    flexible_content.filter(x => x.type == 'GtagWithEvent')
  const navType = meta?.navigation_type

  //list page hide navigation
  const hideNav = [''].includes(wagtailPageType)
  const blueNav =
    [
      'GymIndex',
      'OwnAGymPage',
      'ClassIndex',
      'ClassPage',
      'FranchiseeBenefitsIndex',
      'CorporatePartnerIndexPage',
      'FranchiseeBenefitPage',
      'CorporatePartnerPage',
      'GymStudio',
      'GymOpportunityIndex'
    ].includes(wagtailPageType) ||
    (!['Hero'].includes(firstBlockType) &&
      ['StandardPage'].includes(wagtailPageType)) ||
    navType === 'solid'

  const { navigationData, setNavigationData, flatMenuData } = useContext(
    MenuContext
  )

  useEffect(() => {
    if (navigationData && navigationData.hidden !== hideNav) {
      setNavigationData &&
        setNavigationData({ ...navigationData, hidden: hideNav })
    }
  }, [navigationData, hideNav, setNavigationData])

  const siteStructureData = StructureData.Organization(siteInfor, flatMenuData)
  const breadcrumbs = StructureData.BreadcrumbList(pageData, siteInfor)
  const SearchAction = StructureData.SearchAction(siteInfor)

  let NavigationComponent = (
    isFranchisePage?: boolean,
    templateType?: LAYOUT_TEMPLATE
  ) => {
    if (isFranchisePage) {
      return <Header />
    } else {
      let NavComponent = NavigationTemplate(templateType)
      if (meta.layout_template && wagtailPageType == 'Gym') {
        if (meta.layout_template === '2021') {
          NavComponent = NavigationTemplate('you_fitness')
        } else {
          NavComponent = NavigationTemplate(meta.layout_template)
        }
        pageData.template = meta.layout_template as LAYOUT_TEMPLATE
      }


      return (
        <NavComponent
          startPostion={blueNav ? 'relative' : navigationData?.startPostion}
          siteName={pageData.meta.meta_title || siteInfor?.site_name}
          metaType={pageData?.meta?.type}
          gymId={wagtailPageType === 'Gym' ? pageData?.id.toString() : ''}
          gymTitle={wagtailPageType === 'Gym' ? pageData?.title : ''}
          template={
            siteSettings?.site_template && wagtailPageType !== 'Gym'
              ? siteSettings?.site_template
              : (pageData.template as LAYOUT_TEMPLATE)
          }
          joinUrl={
            wagtailPageType === 'Gym' && pageData?.join_url
              ? pageData?.join_url
              : siteSettings?.join_now_url_override
                ? siteSettings.join_now_url_override
                : ''
          }
          popup_form={pageData?.popup_form}
          enquireUrl={siteSettings?.enquire_now_url_override || ''}
          enquireForm={siteSettings?.enquire_now_form || ''}
        />
      )
    }
  }

  const isFranchisePage = pageData.meta.type === 'cms.FranchisePage'

  if (pageProps && pageProps.pageData) {
    if (pageProps.pageData.meta.layout_template === YOU_FITNESS_TEMPLATE) {
      pageProps.pageData.template = PAGE_TEMPLATE.YOU_FITNESS
    } else if (wagtailPageType === 'Gym') {
      pageProps.pageData.template = pageProps.pageData.meta
        .layout_template as LAYOUT_TEMPLATE
    } else {
      pageProps.pageData.template = siteSettings?.site_template
    }
  }
  if (pageData) {
    if (pageData.meta.layout_template === YOU_FITNESS_TEMPLATE) {
      pageData.template = PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE
    } else if (wagtailPageType === 'Gym') {
      pageData.template = pageProps.pageData.meta
        .layout_template as LAYOUT_TEMPLATE
    } else {
      pageData.template = siteSettings?.site_template
    }
  }

  if (isFranchisePage) {
    pageData.template = PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE
  }

  let FooterComponent = FooterTemplate(pageData.template)
  if (meta.layout_template && meta.type == 'gyms.Gym') {
    if (meta.layout_template === '2021') {
      FooterComponent = FooterTemplate('you_fitness')
    } else {
      FooterComponent = FooterTemplate(meta.layout_template)
    }
  }
  return (
    <>
      {siteSettings &&
        siteSettings.facebook_pixel_id &&
        parse(
          facebookPixelScriptWithDynamicType(
            trackingFacebookPixel && !!trackingFacebookPixel.length
              ? trackingFacebookPixel[0].value.tracking_field
              : 'PageView',
            siteSettings.facebook_pixel_id
          )
        )}
      {gtag_with_event && !!gtag_with_event.length && gtag_with_event[0].value.gtag && gtag_with_event[0].value.event &&
        parse(
          dynamicGtagWithEvent(
            gtag_with_event[0].value.gtag,
            gtag_with_event[0].value.event
          )
        )
      }
      {siteSettings &&
        siteSettings.tiktok_pixel_id &&
        parse(
          tiktokPixelScript(
            siteSettings.tiktok_pixel_id
          )
        )
      }
      {siteSettings &&
        siteSettings.linkedin_partner_id &&
        parse(
          LinkedInScript(
            siteSettings.linkedin_partner_id
          )
        )
      }
      {pageData.meta &&
        pageData.meta.facebook_tracking_code && pageData.meta.facebook_tracking_code.length &&
        parse(
          facebookPixelScriptWithDynamicType(
            trackingFacebookPixel && !!trackingFacebookPixel.length
              ? trackingFacebookPixel[0].value.tracking_field
              : 'PageView',
            pageData.meta.facebook_tracking_code
          )
        )}

      {SEO && <NextSeo {...SEO} />}
      {!hideNav && SearchAction && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(SearchAction)
          }}
        />
      )}
      {siteStructureData && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(siteStructureData)
          }}
        />
      )}
      {breadcrumbs && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbs)
          }}
        />
      )}
      {!hideNav && NavigationComponent(isFranchisePage, pageData.template)}
      {Page && (
        <Page pageData={{ ...pageData }} {...pageProps} hideNav={hideNav} />
      )}
      <FooterComponent />
    </>
  )
}

WagtailPage.getInitialProps = async (ctx: PageContext) => {
  const slug = ctx.query && ctx.query.slug ? ctx.query.slug : '/'
  // We want an object of the non-slug query to pass queryParams to the backend
  const queryParams = stripQueryParam(ctx.query, 'slug')

  const { pageData, wagtailPageType } = await cms.getWagtailPageBySlug(
    slug,
    queryParams,
    ctx
  )

  if (slug === '500') {
    pageData.status = 500
  }
  if (pageData.responseStatus === 403 || pageData.responseStatus === 401) {
    // Temporary redirect to ask password page
    ctx.res?.writeHead(302, { Location: `/ask-password?slug=${slug}` })
    ctx.res?.end()
  }

  const { goto, gymId, gymTitle } = ctx.query
  pageData.goto = goto as string
  const paramString = `?goto=${goto ? goto : ''}${gymId ? `&gymId=${gymId}` : ''
    }${gymTitle ? `&gymTitle=${gymTitle}` : ''}`

  const SEO = WagtailMetaToNextSEO(pageData, pageData.meta)

  if (wagtailPageType) {
    let pageProps: any = { ...ctx.query }
    if (pageProps?.ref) delete pageProps.ref // stop ref url param being passed as a component REF
    let Page = Layouts(wagtailPageType)
    if (Page && Page.getInitialProps) {
      ctx.pageData = pageData
      pageProps = {
        ...pageProps,
        ...(await Page.getInitialProps(ctx))
      }
      if (
        ctx.pageData.flexible_content &&
        ctx.pageData.flexible_content[0] &&
        BlocksShowPageTitle.includes(ctx.pageData.flexible_content[0].type)
      ) {
        ctx.pageData.flexible_content[0] = {
          ...ctx.pageData.flexible_content[0],
          isFirstBlock: true
        }
      }
    }

    return {
      pageData,
      wagtailPageType,
      pageProps,
      SEO
    }
  } else {
    // return error code to client
    const { res } = ctx
    if (res) {
      res.statusCode = pageData.responseStatus || pageData.status || 404
    }

    if (pageData.responseStatus == 404 || pageData.status == 404) {
      // On a 404 we want to check for a redirect in the CMS
      const redirectsResponse = await redirects.getRedirects(`/${slug}`, ctx)
      if (
        redirectsResponse &&
        redirectsResponse.results &&
        redirectsResponse.results.length > 0 &&
        ctx.res
      ) {
        const redirect = redirectsResponse.results[0]
        ctx.res.writeHead(redirect.is_permanent ? 301 : 302, {
          Location: redirect.link + paramString
        })
        ctx.res.end()
      }

      // No redirect found - through a 404
      return {
        pageData,
        wagtailPageType,
        SEO,
        pageProps: {
          statusCode: 404
        }
      }
    } else if (pageData.responseStatus == 500 || pageData.status == 500) {
      return {
        pageProps: {
          pageData,
          wagtailPageType,
          SEO,
          statusCode: 500
        }
      }
    }
  }
}

export default WagtailPage
