import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { ButtonLink } from '../base'

export const MenuWrapper = styled.nav<{
  isFixedPosition?: boolean
  bgCorlor?: 'blue' | 'grey-light'
  hideNav?: boolean
  hideMenu?: boolean
}>`
  ${tw`relative w-full flex justify-center flex-wrap z-30 max-w-full`}

  ${({ hideMenu }) => {
    if (hideMenu) {
      return tw`hidden`
    }
  }}

  ${({ bgCorlor }) => {
    switch (bgCorlor) {
      case 'blue':
        return tw`bg-blue text-white`
      default:
        return tw`bg-grey__light`
    }
  }}

  ${({ isFixedPosition, hideNav }) => {
    if (isFixedPosition) {
      return css`
        position: fixed;

        ${!hideNav
          ? `
          top: 135px;
          @media (min-width: 640px) {
            top: 140px;
          }

          @media (min-width: 768px) {
            top: 140px;
          }

          @media (min-width: 1024px) {
            top: 95px;
          }

          @media (min-width: 1280px) {
            top: 95px;
          }`
          : `top: 0;`}
      `
    }
  }}
`

export const SubMenuWrapper = styled.nav<{
  isFixedPosition?: boolean
}>`
  ${tw`relative w-full flex justify-center flex-wrap z-30 max-w-full`}

  ${({ isFixedPosition }) => {
    if (isFixedPosition) {
      return css`
        position: fixed;
        top: 80px;
        @media (max-width: 1024px) {
          top: 80px;
        }
      `
    }
  }}
`

export const MenuItems = styled.div<{ mobileShowed?: boolean }>`
  ${tw`md:flex flex-1 justify-center flex-wrap flex-col md:flex-row w-full`}
  ${({ mobileShowed }) => (mobileShowed ? tw`flex` : tw`hidden`)}
`

export const MenuItem = styled.a<{
  isActive?: boolean
  bgCorlor?: 'blue' | 'grey-light'
}>`
  ${tw`md:py-2 md:px-8 px-4 md:text-center italic uppercase font-display cursor-pointer last:bg-orange last:text-white`}
  ${({ isActive, bgCorlor }) => {
    switch (bgCorlor) {
      case 'blue':
        if (isActive) {
          return tw`bg-orange text-white`
        }
        return tw`text-white hover:bg-orange active:bg-orange hover:text-white active:text-white`
      default:
        if (isActive) {
          return tw`bg-blue__grey text-white`
        }
        return tw`text-blue hover:bg-blue__grey active:bg-blue__grey hover:text-white active:text-white`
    }
  }}
`

// TODO: This container is for demo on storybook and Will be changed when use on a page
export const AdditionalMenuStyled = styled.div<{ displaySearch?: boolean }>`
  ${tw`relative w-full overflow-hidden`}
  &.search-form-enter {
    left: 100%;
    width: 0;
    transition: width 400ms, left 400ms, height 300ms;
  }
  &.search-form-enter-active {
    left: 0;
    width: 50%;
    transition: width 400ms, left 400ms, height 300ms;
  }
  &.search-form-exit {
    left: 0;
    width: 50%;
    transition: width 400ms, left 400ms, height 300ms;
  }
  &.search-form-exit-active {
    left: 100%;
    width: 0;
    transition: width 400ms, left 400ms, height 300ms;
  }
`
export const ButtonWrapper = styled.div`
  ${tw`w-full flex md:hidden items-stretch`}
`
export const ButtonSubWrapper = styled.div`
  ${tw`w-full flex lg:hidden items-stretch`}
`
export const ButtonStyled = styled(ButtonLink)`
  ${tw`flex-1 text-center`}
`

export const MenuSearchWrapper = styled.div<{ fixedPosition: boolean }>`
  ${tw`w-full overflow-y-hidden`}
  bottom: 3.5rem;

  ${({ fixedPosition }) => (fixedPosition ? '' : tw`absolute`)}

  @media (min-width: 768px) {
    bottom: 2.5rem;
  }
`
