import WagtailBlocksSection from '../src/components/wagtail/components'
import { WagtailPageProps, PageContext } from '../src/services/api/types'
import { getFormCMSContent } from '../src/services/api/cms'
import { isNullOrEmpty } from '../src/services/utils'
import { useState, useEffect, useRef } from 'react'
import { defaultHeroFormProps } from '../src/services/mock'
import Modal from '../src/components/modal/Modal'
import dynamic from 'next/dynamic'
import { OwnerEnquireFormProps } from '../src/components/forms/OwnerEnquireForm'
import ReactGA from 'react-ga';


const OwnerEnquireForm = dynamic(
  () => import('../src/components/forms/OwnerEnquireForm'),
  {
    ssr: false
  }
)

const OwnAGymPage = (props: { pageData: WagtailPageProps }) => {
  const {
    pageData: { flexible_content, template }
  } = props

  const [ownEnquire, setOwnEnquire] = useState<OwnerEnquireFormProps | null>(
    null
  )
  const [isShowFormModal, setIsShowFormModal] = useState(false)
  const [isShowHeroFormModal, setIsShowHeroFormModal] = useState(false)

  const ownEnquireRef = useRef<any>()

  const handleShowEnquireModal = () => {
    ownEnquireRef?.current?.setShow(true)
  }

  useEffect(() => {
    const heroBlocks = flexible_content?.filter(
      ctn =>
        (ctn.type === 'HeroWithForm' && ctn.value.form.pop_up_form) ||
        (ctn.type === 'Form' && ctn.value.form.pop_up_form)
    )

    if (heroBlocks && !isNullOrEmpty(heroBlocks)) {
      setOwnEnquire({
        ...heroBlocks[0]?.value.form,
        formHeader: defaultHeroFormProps.formHeader,
        submitText: heroBlocks[0]?.value.form.submit_button_text
      })
    }
  }, [flexible_content])

  useEffect(() => {
    const formBlock: any = flexible_content?.filter(
      (block: any) =>
        (block.type === 'Form' && block.value.pop_up_form === true) ||
        (block.type === 'HeroWithForm' && block.value.form.pop_up_form === true)
    )

    let timer: any
    let formConfig: any

    if (
      formBlock &&
      formBlock[0] &&
      (formBlock[0].type == 'Form' || formBlock[0].type == 'HeroWithForm')
    ) {
      if (formBlock[0].type == 'Form') {
        formConfig = formBlock[0].value
      } else {
        formConfig = formBlock[0].value.form
      }
    } else {
      formConfig = null
    }

    if (formConfig) {
      if (formBlock.length > 1) {
        setIsShowFormModal(true)
        setIsShowHeroFormModal(true)
      } else {
        formBlock[0].type == 'Form'
          ? setIsShowFormModal(true)
          : setIsShowHeroFormModal(true)
      }

      timer = setTimeout(
        () => {
          handleShowEnquireModal()
        },
        formConfig.pop_up_time_delay
          ? formConfig.pop_up_time_delay * 1000
          : 5000
      )

      return () => {
        clearTimeout(timer)
      }
    }
  }, [])
  useEffect(() => {
    ReactGA.event({
      category: 'Page visit',
      action: 'Own a gym landing page',
      nonInteraction: true
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'own_gym_landing_page',
    });
  }, [])

  const newFlexibleContent =
    flexible_content &&
    flexible_content.map(item => {
      if (item.type === 'KeyMessage') {
        const newItem = {
          ...item,
          value: {
            ...item.value,
            centered_call_to_action_button: [
              ...item.value.centered_call_to_action_button.map(
                (button: any) => {
                  return {
                    ...button,
                    onClick: handleShowEnquireModal
                  }
                }
              )
            ]
          }
        }
        item = newItem
      }
      if (item.type === 'CTAPanels') {
        const newReadyStart = {
          ...item,
          value: {
            ...item.value,
            panels: [
              ...item.value.panels.map((panel: any) => {
                if (panel.link && panel.link.button_text === 'Enquire now') {
                  const newPanel = {
                    ...panel,
                    link: {
                      ...panel.link,
                      button_link: {
                        link_target: '_self'
                      },
                      onClick: handleShowEnquireModal
                    }
                  }
                  panel = newPanel
                }
                return panel
              })
            ]
          }
        }
        item = newReadyStart
      }
      if (item.type === 'HeroWithForm') {
        item.value.form.isShowFormModal = isShowHeroFormModal
      }
      return item
    })

  return (
    <>
      {newFlexibleContent && (
        <WagtailBlocksSection blocks={newFlexibleContent.filter(item => !item.value?.pop_up_form)} template={template} />
      )}
      {ownEnquire && (isShowFormModal || isShowHeroFormModal) && (
        <Modal
          showCloseIcon={true}
          title='Enquire Form'
          ref={ownEnquireRef}
          template={template}
        >
          <OwnerEnquireForm {...ownEnquire} template={template} />
        </Modal>
      )}
    </>
  )
}

OwnAGymPage.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: WagtailPageProps
  } = {
    pageData: ctx.pageData
  }

  props.pageData.flexible_content = props.pageData.flexible_content?.map(
    block => {
      if (block.type === 'Form') {
        const newFormBlock = {
          ...block,
          value: {
            ...block.value,
            form: block.value
          }
        }
        block = newFormBlock
      }
      return block
    }
  )

  const heroBlocks = props.pageData.flexible_content?.filter(
    ctn => ctn.type === 'HeroWithForm' || ctn.type === 'Form'
  )

  if (heroBlocks && !isNullOrEmpty(heroBlocks)) {
    for (let i = 0; i < heroBlocks.length; i++) {
      const slug = heroBlocks[i].value.form.slug
      const formData = await getFormCMSContent(ctx, {
        slug: slug
      })

      heroBlocks[i].value.form = {
        ...heroBlocks[i].value.form,
        ...formData
      }
    }
  }

  return props
}

export default OwnAGymPage
