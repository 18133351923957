import { useState, useEffect } from 'react'
import * as S from './Menu.styled'
import { EnquiryFormRow } from '../forms/MemberEnquiryForm.styled'
import Modal from '../modal/Modal'
import { GymSearchCard } from '../gym-search-card/GymSearchCard'
import { LAYOUT_TEMPLATE } from '../../services/api/types'
export interface SubMenuBlocksProps {
  joinUrl?: string
  popup_form?: boolean
  gymId?: number
  template?: LAYOUT_TEMPLATE
}

export const SubMenu = (props: SubMenuBlocksProps) => {
  const { template, gymId } = props
  const [isFixedPosition, setIsFixedPosition] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 100) {
      if (!isFixedPosition) {
        setIsFixedPosition(true)
      }
    } else {
      if (isFixedPosition) {
        setIsFixedPosition(false)
      }
    }
  }

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll)
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <>
      <S.SubMenuWrapper isFixedPosition={isFixedPosition}>
        <S.ButtonSubWrapper>
        <EnquiryFormRow justify='center'>
        <Modal
                template={template}
                buttonText='Join now'
                cancelText='Cancel'
                isHeroButton={true}
                isMobileButton={true}
                
              >
                <GymSearchCard
                  suburbStep='False'
                  template={template}
                  gym={gymId}

                />
              </Modal>
              </EnquiryFormRow>
        </S.ButtonSubWrapper>
      </S.SubMenuWrapper>
    </>
  )
}

export default SubMenu
