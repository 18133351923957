import { useContext, useState } from 'react'
import { Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { TextInput } from '../../base'
import { SubscribeFormWrap, Form, ErrorMessageText } from './Newsletter.styled'
import { submitForm } from '../../../services/api/cms'
import { SiteContext } from '../../../services/context/SiteContext'
import addressBg from '../../../static/imgs/address-bg2.jpg'
import { RECAPTCHA_KEY } from '../../../services/api/constants'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import * as G from '../../../styles/global.styles'
import * as S from './Newsletter.styled'
import { useEffect } from 'react'

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export interface NewsletterProps {
  newsletterTitle: string
  newsletterMsg: string
  emailPlaceHolder?: string
  subBtnText: string
  successTitle: string
  successMessage: string
  errorTitle: string
  errorMessage: string
  formApiSlug?: string
}

const Newsletterchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter an email address!')
})

export const NewsletterNewLayout = (props: NewsletterProps) => {
  const {
    newsletterTitle,
    newsletterMsg,
    emailPlaceHolder,
    formApiSlug,
    successTitle,
    successMessage,
    errorTitle,
    errorMessage
  } = props
  const { siteSettings } = useContext(SiteContext)
  const formslug = siteSettings?.newsletter_form_slug
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState({
    title: newsletterTitle,
    message: newsletterMsg
  })

  const handleSubmit = async (values: any) => {
    if (!formslug) {
      return
    }

    setLoading(true)
    submitForm(null, formslug, values)
      .then(r => {
        sleep(1000)
        if (r.ok) {
          setText({ title: successTitle, message: successMessage })
        } else {
          setText({ title: errorTitle, message: errorMessage })
        }
        setLoading(false)
        setSubmitted(true)
      })
      .catch(() => {
        setLoading(false)
        setSubmitted(true)
        setText({ title: errorTitle, message: errorMessage })
      })
  }

  const showForm: boolean = !loading && !submitted

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED) {
      loadReCaptcha(RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [])

  return (
    <S.GymBody2>
      <S.GymComp2>
        <S.GymComp2Right bgImage={addressBg}>
          {loading ? (
            <G.SpinnerWrap isYouFitness>
              <G.Spinner />
            </G.SpinnerWrap>
          ) : (
            <>
              <p>{text.title}</p>
              <span>{text.message}</span>
              {showForm && (
                <>
                  <Formik
                    validationSchema={Newsletterchema}
                    onSubmit={handleSubmit}
                    initialValues={{ email: '', recaptcha: '' }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      setFieldValue
                    }: FormikValues) => (
                      <Form onSubmit={handleSubmit}>
                        <SubscribeFormWrap>
                          <TextInput
                            className={'input-custom'}
                            placeholder={
                              emailPlaceHolder
                                ? emailPlaceHolder
                                : 'Enter email address'
                            }
                            placeHolderColor='white'
                            name='email'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            color='white'
                          />
                          <G.BlueButtonCustom type='submit'>
                            Subscribe
                          </G.BlueButtonCustom>
                          <ErrorMessageText>
                            {errors.email && touched.email && errors.email}
                          </ErrorMessageText>
                        </SubscribeFormWrap>

                        {formApiSlug && (
                          <ReCaptcha
                            sitekey={RECAPTCHA_KEY}
                            action='newsletter'
                            verifyCallback={async (token: any) => {
                              if (values['recaptcha'] !== token)
                                await setFieldValue('recaptcha', token, false)
                            }}
                          />
                        )}
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </>
          )}
        </S.GymComp2Right>
        <S.GymComp2Left>
          <G.DefaultImg objectFit={'cover'} src={addressBg} alt={text.title} />
        </S.GymComp2Left>
      </S.GymComp2>
    </S.GymBody2>
  )
}

NewsletterNewLayout.defaultProps = {
  newsletterTitle: `STAY INFORMED`,
  newsletterMsg: `Register for regular updates, blogs and Plus Fitness news`,
  subBtnText: `SUBSCRIBE TO THE NEWSLETTER`,
  formApiSlug: `newsletter-signup`,
  successTitle: 'SUCCESS',
  successMessage:
    'You are now registered for regular updates, blogs and Plus Fitness',
  errorTitle: 'ERROR',
  errorMessage:
    'Sorry there has been a problem submitting your email. Please try later.'
}

export default NewsletterNewLayout
