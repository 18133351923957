import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'

export const OpeningBannerWrapper = styled(G.Container)`
  ${tw`flex justify-center content-center text-center flex-col items-center mb-6`}
`

export const OpeningBannerTitle = styled.div<{
  color?: string
}>`
  line-height: 3rem;
  ${tw`text-5xl text-blue italic uppercase font-extrabold`}
  ${({ color }) => {
    switch (color) {
      case 'blue':
        return tw`text-blue`
      case 'orange':
        return tw`text-orange`
    }
  }}
	text-shadow: 0px 0px 5px #fff, 
               0px 0px 4px #fff, 
			   0px 0px 70px #fff,
			   0px 0px 20px #8c8c8c;
`

export const OpeningSoonButtonWrapper = styled.div`
  ${tw`flex justify-center mt-8`}
`
