import WagtailBlocksSection from '../src/components/wagtail/components'
import { PageContext, WagtailPageProps } from '../src/services/api/types'
import { Main } from '../src/components/franchise-page/Franchise.styled'
import { useContext } from 'react'
import { SiteContext } from '../src/services/context/SiteContext'
import { useEffect } from 'react'
import { getCookie } from '../src/services/api/cookies'
import { isNullOrEmpty } from '../src/services/utils'
import { getFormCMSContent } from '../src/services/api/cms'

const FranchisePage = (props: { pageData: WagtailPageProps }) => {
  const { pageData } = props
  const { setRegisterYourInterestForm, setSiteInfor, siteInfor } = useContext(
    SiteContext
  )

  const blocksWithoutForm =
    pageData.flexible_content?.filter(it => it.type !== 'form') || []
  const form = pageData.flexible_content?.find(it => it.type === 'form')

  useEffect(() => {
    form && setRegisterYourInterestForm && setRegisterYourInterestForm(form)
    const isDownloadable = getCookie('isDownloadable', null)    

    setSiteInfor &&
      setSiteInfor({
        ...siteInfor,
        isDownloadable: isDownloadable ? true : false,
      })
  }, [form, setRegisterYourInterestForm])

  return (
    <Main>
      {pageData && pageData.flexible_content ? (
        <WagtailBlocksSection
          blocks={blocksWithoutForm}
          template='you_fitness'
        />
      ) : null}
    </Main>
  )
}

FranchisePage.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: WagtailPageProps
  } = {
    pageData: ctx.pageData
  }

  const forms = props.pageData.flexible_content?.filter(
    ctn => ctn.type === 'form'
  )
  if (forms && !isNullOrEmpty(forms)) {
    for (let i = 0; i < forms.length; i++) {
      const formData = await getFormCMSContent(ctx, {
        slug: forms[i].value.slug
      })
      forms[i].value = {
        ...forms[i].value,
        ...formData
      }
    }
  }

  return props
}
export default FranchisePage
