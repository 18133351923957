import { useRef, useEffect, useState } from 'react'
import WagtailBlocksSection from '../src/components/wagtail/components'
import {
  WagtailPageProps,
  ButtonAPIProps,
  PageContext,
  LAYOUT_TEMPLATE,
  WagtailBlockProps
} from '../src/services/api/types'
import { getFormCMSContent } from '../src/services/api/cms'
import {
  PAGE_TEMPLATE
} from '../src/services/api/constants'
import { isNullOrEmpty } from '../src/services/utils'
import * as G from '../src/styles/global.styles'
import { BlockTitle } from '../src/components/base'
import { Col, Container, Row } from '../src/styles/grid.styles'
import { Modal } from '../src/components/modal'

const GenericPageTemplate = (props: {
  pageData: WagtailPageProps
  gymId?: string | string[]
  gymTitle?: string | string[]
}) => {
  const {
    pageData: { flexible_content, meta, title, goto, template },
    gymId,
    gymTitle,
  } = props

  const [isShowHeroFormModal, setIsShowHeroFormModal] = useState(false)
  const [modalForm, setModalForm] = useState<WagtailBlockProps[]>([])
  // Two column layout will be use when page have a side form
  const isTwoColLayout =
    flexible_content?.find(block => block.type === 'form') &&
    meta?.two_column_layout

  // Scroll to enquire form for membership
  const blockRef = useRef(null)
  const ownEnquireRef = useRef<any>()
  if (meta.slug === 'memberships' || meta.slug === 'gym-membership') {
    const heroBlock = flexible_content?.find(block => block.type === 'Hero')
    const anchorLinkButton = heroBlock?.value.slides[0]?.value.cta_buttons.cta_button?.find(
      (btn: ButtonAPIProps) => !isNullOrEmpty(btn.button_link.anchor_link)
    )
    if (!isNullOrEmpty(anchorLinkButton) && goto) {
      anchorLinkButton.onClick = () => {
        const enquireFormRef = document.getElementById(goto)
        if (enquireFormRef) {
            const offset = enquireFormRef.getBoundingClientRect()
            window.scrollTo({
              top: offset.top - 60,
              behavior: 'smooth',
            });
            return
        }
      }
    }
  }

  // scroll to form for membership goto=enquiry param
  useEffect(() => {
    if (
      (meta.slug === 'memberships' || meta.slug === 'gym-membership') &&
      ((gymId && gymTitle) || (goto)) &&
      window
    ) {
      setTimeout(() => {
        if(goto){
          const enquireFormReference = document.getElementById(goto)
          if(enquireFormReference){
            const offset = enquireFormReference.getBoundingClientRect()
                window.scrollTo({
                  top: offset.top - 60,
                  behavior: 'smooth',
                });
                return
          }
        }
      }, 500)
    }
  }, [goto])

  // Add page type to Hero CTA (Join us button) on memberships page for open popup
  //@ts-ignore
  const [newFlexibleContent, setNewFlexibleContent] = useState(
    flexible_content &&
      flexible_content.map(item => {
        if (
          item.type === 'Hero' &&
          (meta.slug === 'memberships' || meta.slug === 'gym-membership')
        ) {
          const newHeroSlide = {
            ...item,
            value: {
              slides:
                item.value.slides &&
                item.value.slides.map((slide: any) => {
                  const newSlide = {
                    ...slide,
                    value: {
                      ...slide.value,
                      cta_buttons: {
                        cta_button:
                          slide.value.cta_buttons &&
                          slide.value.cta_buttons.cta_button &&
                          slide.value.cta_buttons.cta_button.map(
                            (cta_button: any, index: number) => {
                              if (index === 1) {
                                const newCtaButton = {
                                  ...cta_button,
                                  page_type: meta.slug
                                }
                                cta_button = newCtaButton
                              }
                              return cta_button
                            }
                          )
                      }
                    }
                  }
                  return newSlide
                })
            }
          }
          item = newHeroSlide
        }
        if (
          item.type === 'form' &&
          (meta.slug === 'memberships' || meta.slug === 'gym-membership')
        ) {
          const newForm = {
            ...item,
            value: {
              ...item.value,
              gym_id: gymId,
              gym_title: gymTitle
            }
          }
          item = newForm
        }
        if (item.type === 'Hero' && meta.slug === 'kick-gear') {
          const newForm = {
            ...item,
            value: {
              ...item.value,
              is_kick_gear: true
            }
          }
          item = newForm
        }

        if (item.type === 'HeroWithForm') {
          item.value.form.isShowFormModal = isShowHeroFormModal
        }
        return item
      })
  )
  const TwoColLayout = ({
    blocks,
    template
  }: {
    blocks: WagtailBlockProps[]
    template: LAYOUT_TEMPLATE
  }) => {
    return template === PAGE_TEMPLATE.YOU_FITNESS ? (
      <>
        <BlockTitle isYouFitness style={{ paddingTop: '1.5rem' }}>
          {title}
        </BlockTitle>
        <WagtailBlocksSection
          template={template}
          blocks={blocks.filter(
            block =>
              block.type === 'ResponsiveImage' ||
              block.type === 'title_and_image' ||
              block.type === 'Hero'
          )}
        />
        <Container noPadding>
          <Row>
            <Col md={7}>
              <WagtailBlocksSection
                template={template}
                blocks={blocks.filter(
                  block =>
                    block.type !== 'form' &&
                    block.type !== 'ResponsiveImage' &&
                    block.type !== 'title_and_image' &&
                    block.type !== 'Hero'
                )}
              />
            </Col>
            <Col md={5}>
              <WagtailBlocksSection
                template={template}
                blocks={blocks.filter(block => block.type === 'form')}
              />
            </Col>
          </Row>
        </Container>
      </>
    ) : (
      <G.FullWidthWrapper className='bg-grey__light'>
        <G.Container contentSize='lg'>
          <G.PageTitle>{title}</G.PageTitle>
          <G.ContainerGrid>
            <G.FullWidthWrapper>
              <WagtailBlocksSection
                template={template}
                blocks={blocks.filter(
                  block =>
                    block.type === 'ResponsiveImage' ||
                    block.type === 'title_and_image' ||
                    block.type === 'Hero'
                )}
              />
            </G.FullWidthWrapper>
            <G.TemplateFormContentContainer>
              <div className='bg-white'>
                <WagtailBlocksSection
                  template={template}
                  blocks={blocks.filter(
                    block =>
                      block.type !== 'form' &&
                      block.type !== 'ResponsiveImage' &&
                      block.type !== 'title_and_image' &&
                      block.type !== 'Hero'
                  )}
                />
              </div>
            </G.TemplateFormContentContainer>
            <G.TemplateFormContainer>
              <WagtailBlocksSection
                template={template}
                blocks={blocks.filter(block => block.type === 'form')}
              />
            </G.TemplateFormContainer>
          </G.ContainerGrid>
        </G.Container>
      </G.FullWidthWrapper>
    )
  }

  useEffect(() => {
    let formBlock: any = newFlexibleContent?.filter(
      (block: any) =>
        (block.type === 'form' && block.value.pop_up_form === true) ||
        (block.type === 'HeroWithForm' && block.value.form.pop_up_form === true)
    )

    formBlock.forEach((element: WagtailBlockProps) => {
      if (element.type == 'HeroWithForm') {
        setIsShowHeroFormModal(true)
      } else {
        const currIndex =
          newFlexibleContent && newFlexibleContent.length
            ? newFlexibleContent.indexOf(element)
            : -2
        if (currIndex > -1) {
          newFlexibleContent?.splice(currIndex, 1)
        }
      }
    })

    formBlock = formBlock.slice(0, 1)

    if (formBlock && formBlock.length && formBlock[0].type == 'HeroWithForm') {
      let heroBlockForm =
        flexible_content && flexible_content.length
          ? flexible_content?.filter(x => x.type == 'form')
          : []

      heroBlockForm = heroBlockForm.slice(0, 1)
      setModalForm(heroBlockForm)
    } else {
      setModalForm(formBlock)
    }

    let timer: any
    let formConfig: any

    if (
      formBlock &&
      formBlock[0] &&
      (formBlock[0].type == 'form' || formBlock[0].type == 'HeroWithForm')
    ) {
      if (formBlock[0].type == 'form') {
        formConfig = formBlock[0].value
      } else {
        formConfig = formBlock[0].value.form
      }
    } else {
      formConfig = null
    }

    if (formConfig) {
      timer = setTimeout(
        () => {
          ownEnquireRef?.current?.setShow(true)
        },
        formConfig.pop_up_time_delay
          ? formConfig.pop_up_time_delay * 1000
          : 5000
      )

      return () => {
        clearTimeout(timer)
      }
    }
  }, [])

  useEffect(() => {
    console.log('modalForm: ', modalForm)
    console.log('flexible_content: ', flexible_content)
    console.log('pageData: ', props.pageData)
  }, [modalForm])

  return (
    <>
      {newFlexibleContent &&
        (!isTwoColLayout ? (
          meta.slug === 'memberships' ||
          meta.slug === 'gym-membership' ||
          meta.slug === 'gym-memberships' ? (
            <>
              <div
                style={{
                  height: template === PAGE_TEMPLATE.YOU_FITNESS ? '1.5rem' : 0
                }}
              />
              <span ref={blockRef}></span>
              <WagtailBlocksSection
                template={template}
                blocks={newFlexibleContent}
              />
            </>
          ) : (
            <>
              <div
                style={{
                  height: template === PAGE_TEMPLATE.YOU_FITNESS ? '1.5rem' : 0
                }}
              />
              <WagtailBlocksSection
                template={template}
                blocks={newFlexibleContent}
              />
            </>
          )
        ) : (
          <TwoColLayout
            blocks={newFlexibleContent}
            template={template as LAYOUT_TEMPLATE}
          />
        ))}
      <Modal
        className='generic'
        showCloseIcon={true}
        ref={ownEnquireRef}
        template={template}
      >
        {modalForm && modalForm.length && (
          <WagtailBlocksSection template={template} blocks={modalForm} />
        )}
      </Modal>
    </>
  )
}

GenericPageTemplate.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: WagtailPageProps
    gymId?: string | string[]
    gymTitle?: string | string[]
  } = {
    pageData: ctx.pageData
  }

  const query = ctx.query
  if (
    props.pageData.meta.slug &&
    (props.pageData.meta.slug === 'memberships' ||
      props.pageData.meta.slug === 'gym-membership') &&
    query &&
    query.gymId &&
    query.gymTitle
  ) {
    props.gymId = query.gymId
    props.gymTitle = query.gymTitle
  }
  const forms = props.pageData.flexible_content?.filter(
    ctn => ctn.type === 'form'
  )
  if (forms && !isNullOrEmpty(forms)) {
    for (let i = 0; i < forms.length; i++) {
      const formData = await getFormCMSContent(ctx, {
        slug: forms[i].value.slug
      })
      forms[i].value = {
        ...forms[i].value,
        ...formData
      }
    }
  }

  const heroBlocks = props.pageData.flexible_content?.filter(
    ctn => ctn.type === 'HeroWithForm'
  )

  if (heroBlocks && !isNullOrEmpty(heroBlocks)) {
    for (let i = 0; i < heroBlocks.length; i++) {
      const formData = await getFormCMSContent(ctx, {
        slug: heroBlocks[i].value.form.slug
      })
      heroBlocks[i].value.form = {
        ...heroBlocks[i].value.form,
        ...formData
      }
    }
  }

  return props
}

export default GenericPageTemplate
