import React from 'react'
import { PAGE_TEMPLATE } from '../src/services/api/constants'
import { LAYOUT_TEMPLATE } from '../src/services/api/types'
import { ButtonLink } from '../src/styles/global.styles'

interface ErrorProps {
  statusCode: number
  site_template?: LAYOUT_TEMPLATE
}

const Error = (props: ErrorProps) => {
  const { statusCode, site_template } = props
  return (
    <>
      {site_template == PAGE_TEMPLATE.YOU_FITNESS ? (
        <div className={`w-full bg-black__deep h-screen`}>
          <div className='container mx-auto py-12'>
            <div className='w-full text-center'>
              <h1 className='text-6xl font-extrabold text-white'>
                The page can't be found
              </h1>
            </div>
            <div className='w-full py-12'>
              {statusCode == 404 ? (
                <h1 className='text-2xl font-bold text-white'>
                  {statusCode} - Error - the page you are looking for might not
                  exist or could be an expired link.
                </h1>
              ) : (
                <h1 className='text-2xl font-bold text-white'>
                  Internal Server Error - Sorry, the page you are trying to
                  access is not working right now.
                </h1>
              )}
            </div>
            <div className='o-layout__item u-margin-top u-margin-bottom'>
              <a href='/' className='button-link'>
                -go to homepage
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full'>
          <div className='container mx-auto py-12'>
            <div className='w-full text-center'>
              <h1 className='text-6xl font-extrabold text-grey__dark'>
                The page can't be found
              </h1>
            </div>
            <div className='w-full py-12'>
              {statusCode == 404 ? (
                <h1 className='text-2xl font-bold text-grey__dark'>
                  {statusCode} - Error - the page you are looking for might not
                  exist or could be an expired link.
                </h1>
              ) : (
                <h1 className='text-2xl font-bold text-grey__dark'>
                  Internal Server Error - Sorry, the page you are trying to
                  access is not working right now.
                </h1>
              )}
            </div>
            <div className='o-layout__item u-margin-top u-margin-bottom'>
              <ButtonLink
                color='blue'
                size='2xl'
                fontWeight='bold'
                href='/'
                data-text={'-go to homepage'}
              >
                <span>-go to homepage</span>
              </ButtonLink>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
Error.getInitialProps = (params: any) => {
  const { res, err } = params
  const statusCode = res ? res.statusCode : err ? err.statusCode : null
  return {
    statusCode
  }
}
export default Error
